import { Engage, ICustomEventInput } from '@sitecore/engage';
import { ExtensionData } from '@sitecore/engage/types/lib/events';
import axios from 'axios';
import { post as cdpPost } from '../lib/utils/axiosCDPFetcher';
import { getCookie, getOrCreateEnrollmentId } from '../lib/utils/enrollmentUtil';
import { CDP_EVENTS, JOURNEY_STEP } from '../constants';
import getDomainName from '../lib/utils/getDomainName';
import { logger } from '../logger';
import getReferralId from '../lib/utils/getReferralId';

interface IEngage {
  [x: string]: any;
  exposedFunctions: Engage;
}
declare global {
  interface Window {
    Engage: IEngage;
  }
}

export interface IUpdateCurrentJourneyPayload {
  enrollmentId: string | null;
  journeyStep: string;
  journeyStatus: string;
  isActiveEnrollment?: string;
}
export const isEngageConfiguredInBrowser = (): boolean => {
  return !!(typeof window !== 'undefined' && window.Engage);
};

export const event = async (
  eventName: string,
  extensionData: ExtensionData,
  eventData?: ICustomEventInput
): Promise<unknown> => {
  if (eventData === undefined) {
    eventData = {
      channel: 'WEB',
      currency: 'EUR',
    };
  }
  return await window.Engage?.exposedFunctions?.event(eventName, eventData, extensionData);
};

export const pageView = async (): Promise<unknown> => {
  // Send VIEW events

  if (typeof window === 'undefined' || !isEngageConfiguredInBrowser()) {
    return new Promise<void>(function (resolve) {
      resolve();
    });
  }
  const eventData = {
    channel: 'WEB',
    currency: 'EUR',
  };

  return await window?.Engage?.exposedFunctions?.pageView(eventData).catch((error: any) => {
    event(CDP_EVENTS.PAGE_LOAD_FAILURE, {
      error: error?.message,
    });
  });
};

export const identityEvent = async (email: string): Promise<unknown> => {
  if (typeof window === 'undefined' || !isEngageConfiguredInBrowser()) {
    return new Promise<void>(function (resolve) {
      resolve();
    });
  }
  const eventData = {
    channel: 'WEB',
    currency: 'EUR',
    email,
    identifiers: [
      {
        id: email,
        provider: 'email',
      },
    ],
  };

  return await window?.Engage?.exposedFunctions?.identity(eventData);
};
//TODO: this is old exp api call, need to replace with new CDP api
//after working on token generation api and done from backend side
export const fetchNextPossiblePathAndData = async (
  experienceId: any,
  isNsc?: boolean,
  isSiteSelect?: boolean,
  pointOfSale?: string
) => {
  const enrollmentID = getOrCreateEnrollmentId();
  const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
  const bidVal = getCookie(cookieName);
  const payload: any = {
    context: {
      channel: 'WEB',
      browserId: window.Engage?.getBrowserId() || bidVal,
      clientKey: process.env.NEXT_PUBLIC_CDP_CLIENT_KEY,
      pointOfSale,
      params: {
        enrollmentId: enrollmentID,
        isNSC: isNsc || false,
        isSiteSelect: isSiteSelect || false,
      },
    },
  };

  try {
    // Make the API call to fetch CDP Data
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_SITECORE_EXPERIMENT_URL}/${experienceId}`,
      payload
    );
    logger.info(`${process.env.NEXT_PUBLIC_SITECORE_EXPERIMENT_URL}/${experienceId}`, {
      response: data,
      payload,
    });
    return data;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_SITECORE_EXPERIMENT_URL}/${experienceId}`, {
      error: error?.response?.data?.developerMessage,
      payload,
    });
  }
};

//Virtual Sites CDP update call
export const updateIsVirtualinCDP = async (
  potentialParticipantID: string | null,
  guestType: string,
  isHumanAPIEnabledForVirtual: boolean,
  sitesData: any,
  potentialId: any,
  potentialParticipant_ID: any,
  patientId: any
) => {
  const sites = sitesData.length > 0 ? sitesData[0] : {};

  sites.isSiteConfirmed = true;
  sites.protocolInfo = {
    potentialId: potentialId,
    patientId: patientId,
    potentialParticipantId: potentialParticipant_ID,
    protocol: sites.protocol,
    sponsorId: '',
    studyId: '',
    studyName: sites.studyName,
    projectCode: sites.projectCode,
    nctid: sites.nctid,
    sponsor: sites.sponsor,
    indication: sites.indication,
    otSponsorId: sites?.otSponsorId ? sites?.otSponsorId : null,
    protocolMDMID: sites?.protocolMDMID ? sites?.protocolMDMID : null,
    parentSponsorMDMID: sites?.parentSponsorMDMID ? sites?.parentSponsorMDMID : null,
    ucpmSponsorName: sites?.ucpmSponsorName ? sites?.ucpmSponsorName : null,
  };

  const payload = isHumanAPIEnabledForVirtual
    ? {
        isVirtual: 'True',
        isHumanAPIEnabledForVirtual: 'True',
        siteInfo: sites,
        referralId: getReferralId(getOrCreateEnrollmentId(), sites?.siteCode),
      }
    : {
        isVirtual: 'True',
        isHumanAPIEnabledForVirtual: 'False',
        siteInfo: sites,
        referralId: getReferralId(getOrCreateEnrollmentId(), sites?.siteCode),
      };
  const URL = `/api/contacts/AddIsVirtual/${potentialParticipantID}?guestType=${guestType}`;
  return await cdpPost(URL, payload);
};

export const updateCurrentJourneyInCDP = async (
  potentialParticipantID: string,
  guestType: string,
  payload: IUpdateCurrentJourneyPayload
): Promise<void> => {
  const URL = `/api/contacts/UpdateJourneyStepStatus/${potentialParticipantID}?guestType=${guestType}`;
  return await cdpPost(URL, payload);
};

export const EXPERIENCE_ID_MAPPER = {
  [JOURNEY_STEP.SCREENER]: process.env.NEXT_PUBLIC_CDP_EXPERIENCE_SCREENER_ID,
  [JOURNEY_STEP.CONTACT_INFO]: process.env.NEXT_PUBLIC_CDP_EXPERIENCE_CONTACTINFO_ID,
  [JOURNEY_STEP.SITESELECTION]: process.env.NEXT_PUBLIC_CDP_EXPERIENCE_SITESELECTION_ID,
};
export const getCookieData = async (
  potentialParticipantID: string,
  guestType = 'visitor'
): Promise<any> => {
  const URL = `/api/contacts/GetCookieData/`;
  const domain = getDomainName();
  const payload = {
    guestRef: potentialParticipantID,
    domain,
    guestType,
  };
  return await cdpPost(URL, payload);
};

export const updateCookiePreference = async (
  potentialParticipantID: string,
  payload: any,
  guestType: string
): Promise<any> => {
  const URL = `/api/contacts/AddOrUpdateCookieDataToDataExtension/${potentialParticipantID}?guestType=${guestType}`;
  return await cdpPost(URL, payload);
};

//Currently this is not in used
export const getJourneyId = async ({
  experienceId,
  queryParameters,
  pointOfSale,
  resumeEnrollmentExpirationDays,
}: any) => {
  const enrollmentID = getOrCreateEnrollmentId(resumeEnrollmentExpirationDays);
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_SITECORE_EXPERIMENT_URL}/${experienceId}`,
      {
        context: {
          channel: 'WEB',
          browserId: window.Engage.getBrowserId(),
          clientKey: process.env.NEXT_PUBLIC_CDP_CLIENT_KEY,
          pointOfSale,
          params: {
            enrollmentId: enrollmentID,
            ...queryParameters,
          },
        },
      }
    );
    return response?.data?.journeyId;
  } catch (_error) {}
};

export const createUrlPaths = (data: any) => {
  const res: { [key: string]: string } = {};
  for (const key in data) {
    res[key] = data[key]?.value?.href;
  }
  return res;
};

export const createAutomaticUrlPaths = (automaticFailureUrls: any) => {
  const automaticFailureUrlsList =
    automaticFailureUrls &&
    automaticFailureUrls.map((urlObj: any) => {
      return {
        key: urlObj?.fields?.key?.value,
        redirectionUrl: urlObj?.fields?.redirectionUrl?.value?.href,
      };
    });
  return automaticFailureUrlsList;
};
