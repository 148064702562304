import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { RootState } from '../../store/store';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { post } from '../utils/axiosFetcher';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { DataOrigin, ClinicalReasearch } from '../utils/ucpmHelper';
import { getCookie } from '../utils/enrollmentUtil';

const useUpdateUCPMJobRecord = (route: any): any => {
  const { sitecoreContext } = useSitecoreContext();
  const isUCPMCallDone = useRef<boolean>(false);
  const siteSettings: any = sitecoreContext?.siteSettings;
  const sourceApp = siteSettings?.source?.fields?.name?.value;
  const potentialId = useSelector(
    (state: RootState) => state.potentialParticipantDetails.potentialId
  );
  const cdpState = (sitecoreContext?.CDP as any)?.confirmedProtocolInfo || {};
  const potentialIdFromCDP = (sitecoreContext?.CDP as any)?.potentialId;
  const cdpData = useSelector((state: RootState) => state?.cdpData);

  const getOtSponsorId = (isDNQ, isNoSite, isSuccessPage) => {
    if (isDNQ) {
      //single protocol case for DNQ
      if ((sitecoreContext?.siteSettings as any)?.protocols?.length === 1) {
        return (sitecoreContext?.siteSettings as any)?.protocols[0]?.fields?.otSponsorId?.value;
      }
      //multiple protocol case for DNQ
      return (sitecoreContext?.siteSettings as any)?.otSponsorId?.value;
    } else if (isNoSite) {
      return (sitecoreContext?.siteSettings as any)?.otSponsorId?.value;
    } else if (isSuccessPage) {
      return cdpState?.otSponsorId || (sitecoreContext?.siteSettings as any)?.otSponsorId?.value;
    }
  };
  const getParentSponsorMDMID = (isDNQ, isNoSite, isSuccessPage) => {
    if (isDNQ) {
      //single protocol case for DNQ
      if ((sitecoreContext?.siteSettings as any)?.protocols?.length === 1) {
        return (sitecoreContext?.siteSettings as any)?.protocols[0]?.fields
          ?.parentSponsorMDMEntityId?.value;
      }
      //multiple protocol case for DNQ
      return (sitecoreContext?.siteSettings as any)?.parentSponsorMDMEntityId?.value;
    } else if (isNoSite) {
      return (sitecoreContext?.siteSettings as any)?.parentSponsorMDMEntityId?.value;
    } else if (isSuccessPage) {
      return (
        cdpState?.parentSponsorMDMID ||
        (sitecoreContext?.siteSettings as any)?.parentSponsorMDMEntityId?.value
      );
    }
  };

  const getProtocolMDMID = (isDNQ, isNoSite, isSuccessPage) => {
    if (isDNQ) {
      //single protocol case for DNQ
      if ((sitecoreContext?.siteSettings as any)?.protocols?.length === 1) {
        return (sitecoreContext?.siteSettings as any)?.protocols[0]?.fields?.protocolMdmEntityId
          ?.value;
      }
      //multiple protocol case for DNQ
      return (sitecoreContext?.siteSettings as any)?.parentSponsorMDMEntityId?.value;
    } else if (isNoSite) {
      return (sitecoreContext?.siteSettings as any)?.parentSponsorMDMEntityId?.value;
    } else if (isSuccessPage) {
      return (
        cdpState?.protocolMDMID ||
        (sitecoreContext?.siteSettings as any)?.parentSponsorMDMEntityId?.value
      );
    }
  };

  const getSponsorName = (_isDNQ, _isNoSite, isSuccessPage) => {
    if (isSuccessPage) {
      return cdpState?.ucpmSponsorName;
    } else {
      return (sitecoreContext?.siteSettings as any)?.sponsorName?.value;
    }
  };

  const updateUCPMJobRecords = async ({ isDNQ, isNoSite, isSuccessPage }: any) => {
    const payload: any = {
      otSponsorId:
        sourceApp === ClinicalReasearch && !isSuccessPage
          ? ''
          : getOtSponsorId(isDNQ, isNoSite, isSuccessPage),
      parentSponsorMDMID:
        sourceApp === ClinicalReasearch && !isSuccessPage
          ? ''
          : getParentSponsorMDMID(isDNQ, isNoSite, isSuccessPage),
      protocolMDMID:
        sourceApp === ClinicalReasearch && !isSuccessPage
          ? ''
          : getProtocolMDMID(isDNQ, isNoSite, isSuccessPage),
      sponsor: getSponsorName(isDNQ, isNoSite, isSuccessPage),
      iqviaSponsorId: (sitecoreContext?.siteSettings as any)?.iqviaSponsorId?.value,
      guestRef: null, //getCookie('bx_guest_ref'), as per discussion with Manish, this only needed for CR case
      potentialId: potentialId || potentialIdFromCDP,
      dataOrigin: sourceApp === ClinicalReasearch ? DataOrigin.TAEPR : DataOrigin.EPR,
      UCPMStudyName: (sitecoreContext?.siteSettings as any)?.UCPMStudyName?.value || '',
      integrationAttemptHistory: {
        baseUrl: (sitecoreContext?.siteSettings as any)?.baseUrl?.value,
        isHumanApienabled: false, //this field is not needed for now by UCPM
        tenantKey: (sitecoreContext?.siteSettings as any)?.tenantKey?.value,
        enrollmentId:
          getCookie('enrollmentID') ||
          (sessionStorage.getItem('enrollmentID_for_human_api') as string) ||
          '',
        potentialId: potentialId || potentialIdFromCDP,
      },
    };
    return await post(`/UCPM/AddUCPMJobRecord`, payload);
  };

  const { mutate: updateUCPMJob } = useMutation('update-ucpm-jobs-records', updateUCPMJobRecords, {
    onSuccess: () => {
      console.log('success UCPM data updated');
    },
    onError: () => {
      console.log('error UCPM data updated');
    },
  });
  const router = useRouter();

  const getReferralStatus = (router) => {
    //for all DNQ cases patientReferralStatus will be "DNQ"
    const isDNQ = cdpData?.patientReferralStatus === 'DNQ' ? true : false;
    const isNoSite =
      ['No-site-available', 'Site-not-found', 'No-site-convenient'].includes(
        cdpData?.patientReferralStatus
      ) ||
      cdpData?.patientReferralStatus?.includes('No-site-available-Max-Capacity') ||
      false;
    const isSuccessPage = router.asPath.includes('siteselectionsuccess') ? true : false;
    return { isDNQ, isNoSite, isSuccessPage };
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {
    //checking UCPM is enabled or not from sitecore
    const enableUCPMIntegration = (sitecoreContext?.siteSettings as any)?.enableUCPMIntegration
      ?.value as boolean;

    const isOutcomePage = (route?.fields?.isOutcomePage as Field)?.value;
    if (
      isOutcomePage === true &&
      enableUCPMIntegration &&
      router?.isReady &&
      !isUCPMCallDone.current
    ) {
      if (sourceApp === ClinicalReasearch) {
        //TA epr scenario
        const data = getReferralStatus(router);
        isUCPMCallDone.current = true;
        setTimeout(() => {
          updateUCPMJob(data);
        }, 1500);
      } else {
        //Independent Sponser epr scenario
        const data = getReferralStatus(router);
        isUCPMCallDone.current = true;
        setTimeout(() => {
          updateUCPMJob(data);
        }, 1500);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.isReady, cdpState]);
};

export default useUpdateUCPMJobRecord;
